var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"validation",attrs:{"slim":""}},[_c('v-container',{staticClass:"module-default pa-0 mt-3"},[_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c('div',{staticClass:"d-flex justify-center"},[_c('validation-provider',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('a-text-field',{attrs:{"error-messages":errors,"styling":{
              label: 'Enter minutes worked',
              outlined: true,
              hideDetails: true,
              placeholder: '0',
              class: 'module-default__text-field2 mt-12'
            }},model:{value:(_vm.minutes),callback:function ($$v) {_vm.minutes=$$v},expression:"minutes"}})]}}])})],1),_c('div',{staticClass:"d-flex justify-center"},[_c('a-button',{attrs:{"disabled":!_vm.isValid,"styling":{
            loading: _vm.loading && !_vm.loadingBtn,
            xLarge: true,
            color: '#f79961',
            class: 'module-default__log-btn2 white--text font-weight-bold perform-adk-tinker-log',
            depressed: true
          }},on:{"click":_vm.logMinutes}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-clock-outline")]),_vm._v("Log Minutes ")],1)],1),_c('v-alert',{staticClass:"mt-3 font-weight-bold white--text module-default__log-btn2",attrs:{"value":_vm.success === true,"dense":"","close-text":"Close Alert","color":"green"}},[_vm._v("Logged")]),(_vm.error)?_c('v-alert',{staticClass:"jmt-3 font-weight-bold white--text module-default__log-btn2",attrs:{"dense":"","close-text":"Close Alert","color":"red"}},[_vm._v(" Try again later ")]):_vm._e(),_c('div',{staticClass:"d-flex justify-center"},[_c('a-button',{attrs:{"loading":_vm.loadingBtn,"styling":{
            small: true,
            color: '#ffffff',
            class: 'mt-2 perform-adk-tinker-undo',
            depressed: true
          }},on:{"click":_vm.undo}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-undo")]),_vm._v("Undo ")],1)],1)],1),_c('div',{staticClass:"pa-0 mt-5"},[_c('div',{staticClass:"font-weight-bold mb-6 d-flex justify-center"},[_c('div',{staticClass:"tableview__column"},[_c('div',{staticClass:"d-flex align-center"},[_vm._v("Logged Time")]),_c('div',{staticClass:"d-flex align-center mb-3"},[_vm._v(" "+_vm._s(Math.floor(_vm.totalMinutes / 60))+"h "+_vm._s(_vm.totalMinutes % 60)+"m ")]),_c('div',{staticClass:"mb-6 d-flex justify-center",class:_vm.$vuetify.breakpoint.xs ? 'mx-3' : 'mx-0'},[_c('v-chip-group',[(_vm.requiredMinutes - _vm.totalMinutes > 0)?_c('v-chip',{attrs:{"color":"#f79961","small":"","dark":"","rounded":""}},[_vm._v(" "+_vm._s(_vm.requiredMinutes - _vm.totalMinutes)+" Minutes Left ")]):_vm._e(),(_vm.requiredMinutes - _vm.totalMinutes <= 0)?_c('v-chip',{attrs:{"color":"#f79961","small":"","dark":"","rounded":""}},[_vm._v(" Minimum met. Keep logging! ")]):_vm._e(),_c('v-chip',{attrs:{"color":"#f79961","small":"","outlined":"","rounded":""}},[_vm._v(" "+_vm._s(_vm.requiredMinutes)+" Minutes Required ")])],1)],1)])])]),_c('m-data-table',{attrs:{"table-items":_vm.practiceLog,"is-active-filter":true,"loading":_vm.isFetching,"filters":['personal', 'team'],"headers":_vm.tinkerTableHeader}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }