




















































































































/* eslint-disable no-prototype-builtins */
import { defineComponent, computed, ref } from '@vue/composition-api';
import { useUserState, useUserActions, useUserGetters } from '@/store';
import useTeamDoc from '@/composables/useTeamDoc';
import { tinkerTableHeader } from '@/components/molecules/const';

export default defineComponent({
  name: 'MinutesLog',
  components: {
    AButton: () => import('@/components/atoms/AButton.vue'),
    ATextField: () => import('@/components/atoms/ATextInput.vue'),
    MDataTable: () => import('@/components/molecules/m-data-table.vue')
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    adkType: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    isMonitor: {
      type: Boolean,
      default: false
    },
    studentId: {
      type: String,
      default: ''
    }
  },
  setup(props, ctx) {
    const { getUser } = useUserGetters(['getUser']);
    const user: any = computed({
      get: () => getUser.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const { updateAdkData } = useUserActions(['updateAdkData']);
    const minutes = ref(0);
    const validation = ref('');
    const teamDoc = ref();
    const loadingBtn = ref(false);
    const success = ref(false);
    const error = ref(false);
    const isFetching = ref(false);
    const isValid = computed(() => {
      if (minutes.value > 0) {
        return true;
      }
      return false;
    });
    const practiceLog: any = ref([]);
    const { getTeamDoc }: any = useTeamDoc();
    async function loadInitialData() {
      isFetching.value = true;
      teamDoc.value = await getTeamDoc({
        program_id: ctx.root.$route.params.programId || props.value?.data?.program_id,
        user_id: props.isMonitor ? props.studentId : user.value?._id.toString(),
        public_id: props?.value?.data?._id
      });
      const index = teamDoc.value?.adks?.findIndex(
        a => a?.name?.toLowerCase() === props?.adkType?.toLowerCase()
      );
      if (teamDoc.value?.adks?.[index]?.practiceLog) {
        practiceLog.value = teamDoc.value?.adks?.[index]?.practiceLog || 0;
      }
      isFetching.value = false;
    }
    loadInitialData();

    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const adkData = ref(
      programDoc.value?.data?.adks?.find(
        a => a?.name?.toLowerCase() === props?.adkType?.toLowerCase()
      )
    );

    const minHours = computed(() => {
      return (
        adkData.value?.fields?.find(f => f.model === 'minimumHoursNow')?.setup?.minimumHoursNow ||
        adkData.value?.fields?.find(f => f.model === 'minimumHoursNow')?.preset?.minimumHoursNow
      );
    });

    const requiredMinutes = parseInt(minHours.value, 10) * 60;

    const totalMinutes = computed(() => {
      let sum = 0;
      practiceLog.value.forEach(item => {
        sum += JSON.parse(item.minutes);
      });
      const data = {
        practiceLog: sum || 0,
        minimumHoursNow: requiredMinutes
      };
      ctx.emit('loggedHours', data);
      return sum;
    });

    const fieldIndex = computed(() => {
      return adkData.value?.fields?.findIndex(f => f.name === 'm-minutes-log');
    });

    async function logMinutes() {
      const log: any = ref({
        minutes: minutes.value,
        timestamp: new Date(),
        name: `${user?.value?.firstName} ${user?.value?.lastName}`,
        user_id: user.value?._id,
        avatar: user.value?.profile ? user.value?.profile.small : ''
      });
      practiceLog.value.push(log.value);
      const data = {
        practiceLog: practiceLog.value,
        minimumHoursNow: minHours.value
      };
      adkData.value.fields[fieldIndex.value].value = { ...data };
      ctx.emit('update-adk');
      ctx.emit('loggedHours', data);
      minutes.value = 0;
      await updateAdkData({
        program_id: teamDoc.value?._id?.toString(),
        document_type: 'ProgramTeam',
        data: { ...data, name: props.adkType }
      });
    }

    async function undo() {
      loadingBtn.value = true;
      if (practiceLog.value.length > 0) {
        const index = practiceLog.value.length - 1;
        practiceLog.value.splice(index, 1);
        ctx.emit('update-adk');
        const data = {
          practiceLog: practiceLog.value,
          name: props.adkType
        };
        await updateAdkData({
          program_id: teamDoc.value._id.toString(),
          document_type: 'ProgramTeam',
          data: { ...data }
        });
        loadingBtn.value = false;
      }
    }

    return {
      practiceLog,
      undo,
      logMinutes,
      isFetching,
      minutes,
      minHours,
      isValid,
      requiredMinutes,
      loadingBtn,
      success,
      error,
      validation,
      totalMinutes,
      tinkerTableHeader,
      teamDoc
    };
  }
});
